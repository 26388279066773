/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Silicon Prairie News recently interviewed our CEO about growing his previous company from startup to industry disrupter and how our founders' experiences from that company led them to start Prismatic."), "\n", React.createElement(_components.p, null, "Read the interview ", React.createElement(_components.a, {
    href: "https://siliconprairienews.com/2019/10/building-on-experience-how-one-companys-exit-led-to-a-second-company/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "here"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
